import React from 'react'
import BlogInfoSectionTitle from '../BlogInfoSectionTitle';
import Facebook from 'assets/icons/socialMedia/Icon_FB.svg'
import LinkedIn from 'assets/icons/socialMedia/Icon_Li.svg'
import Instagram from 'assets/icons/socialMedia/Icon_Insta.svg'
import './socialMediaSection.scss'

const SocialMediaSection = (props) => {
    return (
        <div className="blogSocialMediaContainer">
            <BlogInfoSectionTitle 
                title= {props.title}
            />
            <div className="socialMediaLogos">
                <a href={props.facebookLink} target="_blank">
                    <img src={Facebook}  alt="Facebook Logo"/>
                </a>
                <a href={props.linkedInLink} target="_blank">
                    <img src={LinkedIn}  alt="Linkedin Logo" className="MidLogo"/>
                </a>
                <a href={props.instagramLink} target="_blank">
                    <img src={Instagram}  alt="Instagram Logo"/>
                </a>
            </div>
        </div>
    )
}

export default SocialMediaSection;