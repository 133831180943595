import React from 'react'
import { graphql } from 'gatsby'
import CategoryIcon from '@material-ui/icons/Category'
import ScheduleIcon from '@material-ui/icons/Schedule'
import { Link } from 'gatsby'
import { Button } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import get from 'lodash/get'
import AppStateProvider from '../state'
import Layout from '../components/Layout'
import { Container } from 'components/PageContainer'
import './featuredPost.scss'
import BlogHero from 'src/components/Blog/BlogHero'
import RecentPosts from 'src/components/Blog/RecentPosts'
import SocialMediaSection from 'src/components/Blog/SocialMediaSection'
import { BlogPostJsonLd } from 'gatsby-plugin-next-seo'

class BlogPostTemplate extends React.Component {
  render() {
    let posts = get(this.props, 'data.allContentfulBlogPost.edges')
    const post = get(this.props, 'data.contentfulBlogPost')
    const url = get(this.props, 'data.site.siteMetadata.url')
    posts = posts.map((obj) => ({ slug: obj.node.slug, title: obj.node.title }))
    

    return (
      <AppStateProvider>
        <Layout
          title={`${post.title} | SwiftDoc`}
          location={this.props.location}
          description={post.description.childMarkdownRemark.excerpt}
          image={post.heroImage.fluid.src} 
        >
          <BlogPostJsonLd
            url={`${url}/${post.slug}`}
            title={post.title}
            images={[
              post.heroImage.fluid.src
            ]}
            datePublished={post.jsonldPublishDate}
            dateModified={post.jsonldUpdatedAt}
            authorName={post.author.name}
            description={post.description.childMarkdownRemark.excerpt}
          />
          <BlogHero pageTitle={post.title} noBackground={true} />
          <Container>
            <div className="blogPageContainer">
              <div className="blogContainer">
                <div className="featuredPostContainer">
                  <div className="featuredPostHeader">
                    <h1 className="featuredPostTitle">{post.title}</h1>
                    <hr className="featuredPostHr" />
                    <div className="featuredPostDateCategories">
                      <ScheduleIcon /> {post.publishDate}
                    </div>
                    <div className="featuredPostCategoriesChip">
                      <CategoryIcon />
                      {post.categories &&
                        post.categories.map((cat, i) => (
                          <div key={cat.id}>
                            <Chip
                              size="small"
                              label={cat.title.toUpperCase()}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                  <div
                    className="featuredPost"
                    dangerouslySetInnerHTML={{
                      __html: post.body.childMarkdownRemark.html,
                    }}
                  />

                  <div className="backToBlogButton">
                    <Link to={`/news`}>
                      <Button
                        className="bookAppointment"
                        style={{
                          backgroundColor: '#19D5D3',
                          color: 'white',
                          border: 'none',
                        }}
                        variant="outlined"
                      >
                        Back to blog
                      </Button>
                    </Link>
                  </div>
                  {post.tags && post.tags.length > 0 && (
                    <div className="featuredPostTags">
                      {' '}
                      TAGS:
                      <hr className="featuredPostHr" />
                      <div>
                        {post.tags.map((tag, i) => {
                          ;<span key={i}>
                            <span>{tag.toUpperCase()}</span>
                            {post.tags.length - 1 > i && (
                              <span className="dividerTag"> / </span>
                            )}
                          </span>
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className="blogInfoContainer">
                  {/* <BlogSubscription
                          title={'Suscribe to get our latest news'}
                          subscriptionText={'Receive the latest news from SwiftDoc direct to your inbox.'}
                        /> */}
                  <SocialMediaSection
                    title={'Follow SwiftDoc'}
                    facebookLink={'https://www.facebook.com/swiftdocgp'}
                    linkedInLink={
                      'https://au.linkedin.com/company/swiftdoc-pty-ltd'
                    }
                    instagramLink={'https://www.instagram.com/swiftdoc/'}
                  />

                  <RecentPosts title={'Recent posts'} posts={posts} />
                </div>
              </div>
            </div>
          </Container>
        </Layout>
      </AppStateProvider>
    )
  }
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug1($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM Do, YYYY")
      updatedAt(formatString: "MMMM Do, YYYY")
      jsonldPublishDate: publishDate
      jsonldUpdatedAt:updatedAt
      tags
      slug
      categories {
        id
        title
      }
      author {
        name
      }
      description {
        childMarkdownRemark {
          excerpt
        }
      }
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_tracedSVG
          src
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      
    }
    allContentfulBlogPost(sort: { fields: [updatedAt] }, limit: 5) {
      edges {
        node {
          title
          slug
        }
      }
    }
    site {
      siteMetadata {
        url
      }
    }
  }
`
