import React from 'react'
import './blogInfoSectionTitle.scss'

const BlogInfoSectionTitle = (props) => {
    return (
        <div className="blogInfoTitleContainer">
            <h5 className="blogInfoTitle">{props.title}</h5>
        </div>
    )
}

export default BlogInfoSectionTitle;