import React from 'react'
import BlogInfoSectionTitle from '../BlogInfoSectionTitle'
import { Link } from 'gatsby'
import './recentPost.scss'

const RecentPosts = (props) => {
  const recentPosts = props.posts

  return (
    <div className="blogRecentPostsContainer">
      <BlogInfoSectionTitle title={props.title} />
      {recentPosts.map((post, i) => (
        <Link to={`/${post.slug}`} key={i}>
          <p className="blogRecentPostsTitle">{post.title}</p>
        </Link>
      ))}

      <hr className="blogRecentPostsHr" />
    </div>
  )
}

export default RecentPosts
